/* stylelint-disable custom-property-empty-line-before */

:root {
    /* LEGACY COLORS */
    --blackLighten60LegacyColor: #f5f5f5;
    --blackLighten50LegacyColor: #e5e5e5;
    --blackLighten50LegacyRgb: 229 229 229;
    --blackLighten40LegacyColor: #c5c5c5;
    --blackLighten30LegacyColor: #999999;
    --blackLighten20LegacyColor: #444444;
    --blackLighten10LegacyColor: #222222;
    --blackBaseLegacyColor: #121212;

    --turquoiseLighten60LegacyColor: #e0f2ef;
    --turquoiseLighten40LegacyColor: #c5e6e0;
    --turquoiseLighten20LegacyColor: #a7dbd4;
    --turquoiseBaseLegacyColor: #88d0c8;
    --turquoiseDarken20LegacyColor: #6da6a1;
    --turquoiseDarken40LegacyColor: #4e7c78;
    --turquoiseDarken60LegacyColor: #2d5350;

    --blueLighten60LegacyColor: #cfd0dd;
    --blueLighten40LegacyColor: #a9aec3;
    --blueLighten20LegacyColor: #8790ae;
    --blueBaseLegacyColor: #6a789a;
    --blueDarken20LegacyColor: #53607d;
    --blueDarken40LegacyColor: #3c455d;
    --blueDarken60LegacyColor: #21293b;

    --violetLighten60LegacyColor: #c3b7cf;
    --violetLighten40LegacyColor: #9781ab;
    --violetLighten20LegacyColor: #72558e;
    --violetBaseLegacyColor: #502b78;
    --violetDarken20LegacyColor: #401c61;
    --violetDarken40LegacyColor: #2b0647;
    --violetDarken60LegacyColor: #16002c;

    --burgundyLighten60LegacyColor: #d6bcc3;
    --burgundyLighten40LegacyColor: #ba889a;
    --burgundyLighten20LegacyColor: #a15a75;
    --burgundyBaseLegacyColor: #8c285d;
    --burgundyDarken20LegacyColor: #701948;
    --burgundyDarken40LegacyColor: #530532;
    --burgundyDarken60LegacyColor: #360019;

    --redLighten60LegacyColor: #edc4bb;
    --redLighten40LegacyColor: #e09288;
    --redLighten20LegacyColor: #d46360;
    --redBaseLegacyColor: #c8233f;
    --redBaseLegacyRgb: 200 35 63;
    --redDarken20LegacyColor: #9e182f;
    --redDarken40LegacyColor: #75051b;
    --redDarken60LegacyColor: #4e0000;

    --errorLegacyColor: #ff0000;
    --successLegacyColor: #09893b;
    --successLegacyRgb: 9 137 59;
    --warningLegacyColor: #bb3a26;
    --infoLegacyColor: #ff9102;

    /* END OF LEGACY COLORS */

    --textColor: var(--blackBaseColor);
    --textRgb: var(--blackBaseRgb);

    /* HK PRIMARY COLOR */

    --primaryHKLighten60Color: #f9ccd2;
    --primaryHKLighten40Color: #f08897;
    --primaryHKLighten20Color: #e7445c;
    --primaryHKBaseColor: #c61932;
    --primaryHKDarken20Color: #9e182f;
    --primaryHKDarken40Color: #4e0000;
    --primaryHKDarken60Color: #3e0000;

    /* HK SUPPORT 01 COLOR */

    --support01HKLighten40Color: #fff7c1;
    --support01HKLighten20Color: #fff197;
    --support01HKBaseColor: #ffed78;
    --support01HKDarken20Color: #ffcb05;
    --support01HKDarken40Color: #c39a00;

    /* HK SUPPORT 02 COLOR */

    --support02HKLighten20Color: #d5eef6;
    --support02HKLighten40Color: #dff2f8;
    --support02HKBaseColor: #94d6da;
    --support02HKDarken20Color: #24aebc;
    --support02HKDarken40Color: #1b838d;
    --support02HKDarken60Color: #0d4147;

    /* HK SUPPORT 03 COLOR */

    --support03HKLighten60Color: #f4f2fb;
    --support03HKLighten40Color: #cac5dd;
    --support03HKLighten20Color: #7d7daa;
    --support03HKBaseColor: #434b63;
    --support03HKDarken20Color: #33394b;
    --support03HKDarken40Color: #1f222e;
    --support03HKDarken60Color: #0c0d11;

    /* FK SUPPORT PRIMARY */

    --primaryFKLighten60Color: #eee9f4;
    --primaryFKLighten40Color: #cbbcdd;
    --primaryFKLighten20Color: #9779bc;
    --primaryFKBaseColor: #7551a1;
    --primaryFKDarken20Color: #4e316d;
    --primaryFKDarken40Color: #2a0a3f;
    --primaryFKDarken60Color: #1f072f;

    /* FK SUPPORT 03 COLOR */

    --support03FKLighten60Color: #c3eff4;
    --support03FKLighten40Color: #87dfe8;
    --support03FKLighten20Color: #4bcfdd;
    --support03FKBaseColor: #24aebc;
    --support03FKDarken20Color: #1b838d;
    --support03FKDarken40Color: #12575e;
    --support03FKDarken60Color: #092c2f;

    /* FK SUPPORT 04 COLOR */

    --support04FKLighten60Color: #fde7f2;
    --support04FKLighten40Color: #fac3df;
    --support04FKLighten20Color: #f379b6;
    --support04FKBaseColor: #ee3d96;
    --support04FKDarken20Color: #ce1271;
    --support04FKDarken40Color: #890c4b;
    --support04FKDarken60Color: #450626;

    /* GRAYSCALE */

    --whiteBaseColor: #ffffff;
    --whiteBaseRgb: 255 255 255;
    --blackLighten80Color: #f9f9f9;
    --blackLighten60Color: #f3f3f3;
    --blackLighten50Color: #e7e7e8;
    --blackLighten40Color: #c7c8ca;
    --blackLighten40Rgb: 199 200 202;
    --blackLighten30Color: #8a8c8f;
    --blackLighten20Color: #4d4d4f;
    --blackLighten20Rgb: 77 77 79;
    --blackLighten17Color: #434345;
    --blackLighten15Color: #3f3f41;
    --blackLighten12Color: #2e2e2e;
    --blackLighten11Color: #2a2a2a;
    --blackLighten10Color: #212121;
    --blackBaseColor: #000000;
    --blackBaseRgb: 0 0 0;

    /* ACTION */

    --actionBlueLighten60Color: #f5f9ff;
    --actionBlueLighten40Color: #e0edff;
    --actionBlueLighten20Color: #99c4ff;
    --actionBlueBaseColor: #146aeb;
    --actionBlueDarken20Color: #1055bc;
    --actionBlueDarken40Color: #082a5e;
    --actionBlueDarken60Color: #04152f;

    /* NOTIFICATION */

    --notificationFocusLighten60Color: #d3f2ff;
    --notificationFocusBaseColor: #0192d0;
    --notificationFocusDarken20Color: #0074a6;
    --notificationSuccessLighten60Color: #d4f3e6;
    --notificationSuccessBaseColor: #03804d;
    --notificationWarningLighten60Color: #ffe6c1;
    --notificationWarningBaseColor: #ff8b00;
    --notificationErrorLighten60Color: #fdded8;
    --notificationErrorBaseColor: #b12a0b;

    /* MY PAGE */

    --mkBlueLighten10Color: #b8d2f9;
    --mkBlueLighten20Color: #e0ebfe;

    --mkGreenBaseColor: #057b42;
    --mkGreenLighten20Color: var(--notificationSuccessLighten60Color);

    --mkYellowBaseColor: #ffca00;
    --mkGrayBaseColor: var(--blackLighten40Color);

    --mkRedBaseColor: #9f142d;
    --mkRedLighten20Color: var(--notificationWarningLighten60Color);

    --myPageGutterWidth: 24px;

    /* TODO: Move feature-specific variables to proper context (another story) */

    /* STUDY PAGE */

    $studyPageNavigationHeight: 72px;
    --studyPageNavigationVerticalPadding: 16px;
    --studyPageNavigationTabletHorizontalPadding: 16px;
    --studyPageNavigationHeight: 72px;
    --studyPageHeroContentColumnDesktop: 8 / span 5;
    --studyPageHeroContentColumnDesktop2K: 9 / span 4;
    --studyPageNavigationZIndex: 3;

    /* OTHER */

    --baseFontFamily: Graphik, sans-serif;
    --serifFontFamily: Produkt, serif;

    --headerHeight: 112px;
    --headerMobileHeight: 80px;
    --infoBannerHeight: 48px;
    --infoBannerMobileHeight: 84px;
    --wrapperWidth: 1200px;
    --wrapperHorizontalPadding: 32px;
    --gutterWidth: 32px;
    --richtextBlockVerticalMargin: 32px;

    --boxShadow: 0 0 8px 0 rgb(var(--blackBaseRgb) / 10%);
}

/* stylelint-enable custom-property-empty-line-before */
