@define-mixin input $height: 48px {
    @mixin onFocus {
        outline: 1px solid var(--actionBlueBaseColor);
    }
    width: 100%;
    height: $height;
    padding: 13px 15px;
    font-size: 16px;
    line-height: 24px;
    border: 1px solid var(--blackLighten40Color);
    border-radius: 0;
    color: var(--blackBaseColor);

    &::placeholder {
        color: var(--blackLighten30Color);
        font-family: var(--baseFontFamily);
        font-size: 16px;
    }

    &:disabled {
        color: var(--blackLighten30Color);
        border-color: var(--blackLighten40Color);
        background-color: var(--blackLighten60Color);
    }
}

@define-mixin select {
    @mixin p;
    margin: 0;
    appearance: none;
    background-image: svg-load('chevron-down.svg');
    background-repeat: no-repeat;
    background-position: right 16px top 50%;
    background-color: var(--whiteBaseColor);
    background-size: 24px;
    line-height: 1;
    opacity: 1;

    &:disabled {
        background-image: svg-load('chevron-down.svg', fill: #8a8c8f);
    }

    & .Form__option {
        width: 100%;
    }
}

@define-mixin checkbox {
    position: relative;
    padding-left: 40px;

    & label {
        font-weight: 400;
    }

    & input[type='checkbox'] {
        position: absolute;
        left: -9999px;

        &:hover + label::before {
            box-shadow: inset 0 0 4px 0 var(--blackLighten40Color);
        }

        &:checked + label::after {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            left: 6px;
            top: 5px;
            background: var(--blackBaseColor);
        }

        &:focus + label::before {
            border-color: var(--blackLighten10Color);
        }

        & + label {
            cursor: pointer;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: -1px;
                width: 24px;
                height: 24px;
                background: var(--whiteBaseColor);
                border: 1px solid var(--blackLighten40Color);
            }
        }
    }

    &.Form__fieldError {
        & input[type='checkbox'] {
            &:focus + label::before {
                outline: var(--blackLighten10Color) solid 1px;
            }
        }
    }
}

@define-mixin radio {
    position: relative;

    &::before,
    &::after {
        content: '';
        position: absolute;
        border-radius: 100%;
    }

    &::before {
        width: 1.4em;
        height: 1.4em;
        border: 0.1em solid var(--blackLighten30Color);
        transition: border-color 0.2s ease-out;
    }

    &::after {
        width: 0.6em;
        height: 0.6em;
        transition: background-color 0.2s ease-out;
    }
}

@define-mixin fieldError {
    @mixin p30;
    color: var(--primaryHKBaseColor);
    display: block;
}
